import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from './routes/history';
import PrivateRoute from './routes/PrivateRoute';
import AuthenticationRoute from './routes/AuthenticationRoute';
import NotFound from './components/NotFound';
import LoadingIndicator from './components/loadingIndicator/LoadingIndicator';
import DrawerRouterContainer from './components/DrawerRouterContainer.jsx';
import { AppContext } from './AppContext';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import esNumbers from 'cldr-numbers-full/main/es/numbers.json';
import esLocalCurrency from 'cldr-numbers-full/main/es/currencies.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es/dateFields.json';
import { SITE_BASE_PATH, USER_ROLE } from './constant';
import store from './redux/store';
import { enMessages } from './messages/en-US';

import 'hammerjs';
import '@progress/kendo-theme-default/dist/all.css';
import './App.scss';
import ScrollTop from './components/ScrollTop';
import 'bootstrap/dist/css/bootstrap.min.css';


const LoginScreen = lazy(() => import('./features/login/LoginScreen.jsx'));
const ProfileDetails = lazy(() => import('./features/login/ProfileDetails.jsx'));
const AssignPlan = lazy(() => import('./features/admin/AssignPlan.jsx'))
const MarginProfile = lazy(() => import('./features/margin/Margin'))
const EditMarginProfile = lazy(() => import('./features/margin/EditMarginProfile'))
const AssociatePartner = lazy(() => import('./features/margin/AssociatePartner'))
const SelfProfile = lazy(() => import('./features/profile/SelfProfile.jsx'));
const SignUpEmployee = lazy(() => import('./features/login/SignUpEmployee.jsx'));
const SignUpPartner = lazy(() => import('./features/login/SignUpPartner.jsx'));
const TrialPlansList = lazy(() => import('./features/plan/TrialPlansList.jsx'));
const PublishedPlansList = lazy(() => import('./features/plan/PublishedPlansList.jsx'));
const PublishedSpecificPlansList = lazy(() => import('./features/plan/PublishedSpecificPlansList.jsx'))
const DraftPlanList = lazy(() => import('./features/plan/DraftPlan.jsx'));
const EditPlan = lazy(() => import('./features/plan/EditPlan.jsx'));
const AddNewPlan = lazy(() => import('./features/plan/NewPlan.jsx'));
const CustomerList = lazy(() => import('./features/Customer/CustomerList'));
const FinanceInfo = lazy(() => import('./features/finance/FinanceInfo'));
const FinanceInfoPending = lazy(() => import('./features/finance/FinanceInfoPending'));
const FinanceInfoAccepted = lazy(() => import('./features/finance/FinanceInfoAccepted'));
const FinanceInfoRejected = lazy(() => import('./features/finance/FinanceInfoRejected'));

//ROLE - FINANCE
const FinancePendingDetails = lazy(() => import('./features/finance/FinancePendingDetails'));
const FinanceRejectedDetails = lazy(() => import('./features/finance/FinanceRejectedDetails'));
const FinanceAcceptedDetails = lazy(() => import('./features/finance/FinanceAcceptedDetails'));
const PartnerPendingPayments = lazy(() => import('./features/finance/PartnerPendingPayments'));
const PartnerAcceptedPayments = lazy(() => import('./features/finance/PartnerAcceptedPayments'));
const PartnerRejectedPayments = lazy(() => import('./features/finance/PartnerRejectedPayments'));
const PartnerCreditDebitNotes = lazy(() => import('./features/finance/PartnerCreditDebitNotes'));
const RecallSubscriptionList = lazy(() => import('./features/finance/RecallSubscription/RecallSubscriptionList'));
const RecallHistory = lazy(() => import('./features/finance/RecallSubscription/RecallHistory.jsx'))


const PriceCalculator = lazy(() => import('./features/priceCalculator/PriceCalculator'));
const Admin = lazy(() => import('./features/admin/Admin.jsx'));
const UnAuthorizedAccess = lazy(() => import('./components/UnAuthorizedAccess'));
const DownloadPlans = lazy(() => import('./features/plan/DownloadPlans'));
const DownloadMarginProfiles = lazy(() => import('./features/margin/DownloadMarginProfiles'));
const FinanceDownload = lazy(() => import('./features/finance/DownloadFinance'))
const FindUser = lazy(() => import('./features/admin/FindUser.jsx'));
const UserDetail = lazy(() => import('./features/admin/UserDetail.jsx'));
const ViewQuotationHistory = lazy(() => import('./features/priceCalculator/ViewQuotationHistory.jsx'));

const StatusCustomerClaim = lazy(() => import('./features/claim/StatusCustomerClaim.jsx'));
const CustomerClaim = lazy(() => import('./features/claim/CustomerClaim.jsx'));
const AcceptedClaim = lazy(() => import('./features/claim/AcceptedClaim.jsx'));
const RejectedClaim = lazy(() => import('./features/claim/RejectedClaim.jsx'));
const PendingClaim = lazy(() => import('./features/claim/PendingClaim.jsx'));

//Discount Approval Hierarchy
const MasterDiscount = lazy(() => import('./features/discountApprovalHierarchy/MasterDiscount'));
const UserDiscount = lazy(() => import('./features/discountApprovalHierarchy/UserDiscount'));
const DiscountApprovalHierarchy = lazy(() => import('./features/discountApprovalHierarchy/DiscountApprovalHierarchy'));
const OrgChart = lazy(() => import('./features/discountApprovalHierarchy/OrgChart'));

const CustomerTimeline = lazy(() => import('./features/Customer/timeline/CustomerTimeline'))
const SearchCustomer = lazy(() => import('./features/Customer/timeline/SearchCustomer'))
const ExtendValidity = lazy(() => import('./features/Customer/ExtendValidity'))
const RevokePlan = lazy(() => import('./features/Customer/RevokePlan'))
const ChangeOrgType = lazy(() => import('./features/Customer/changeOrgType/ChangeOrgType'))
const LockCustomer = lazy(() => import('./features/Customer/lockTrialCustomer/LockCustomer'))
const ViewLockCustomer = lazy(() => import('./features/Customer/lockTrialCustomer/ViewLockCustomer'))
const LockInPeriodExpiring = lazy(() => import('./features/Customer/lockTrialCustomer/LockInPeriodExpiring'))
const LockInPeriodExpired = lazy(() => import('./features/Customer/lockTrialCustomer/LockInPeriodExpired'))

const ForgetPass = lazy(() => import('./features/login/ForgetPass.jsx'));

const BulkDiscountCoupon = lazy(() => import('./features/coupones/BulkDiscountCoupon.jsx'));
const RcvdCouponRequest = lazy(() => import('./features/coupones/RcvdCouponRequest.jsx'));
const CreateNewCoupon = lazy(() => import('./features/coupones/CreateNewCoupon.jsx'));
const ViewEditBulkCoupon = lazy(() => import('./features/coupones/ViewEditBulkCoupon.jsx'));
const CustomerCoupon = lazy(() => import('./features/coupones/CustomerCoupon'));
const CreateCustomerCoupon = lazy(() => import('./features/coupones/CreateCustomerCoupon'));
const LedgerDraftStatement = lazy(() => import('./features/ledgerDraftStatement/LedgerDraftStatement'));
const PaymentMode = lazy(() => import('./features/ledgerDraftStatement/PaymentMode'));

//For Dashboard
const PlanShareDashboard = lazy(() => import('./features/dashboard/PlanShareDashboard'));
const NewInstallDashboard = lazy(() => import('./features/dashboard/NewInstallDashboard'));
const AuthorizeUser = lazy(() => import('./features/authorizeUser/AuthorizeUser.jsx'));

//Feature Container
const UserActions = lazy(() => import('./features/container/UserActions.jsx'));
const Reports = lazy(() => import('./features/container/Reports.jsx'));

//Customers to Whom only I can Sale
const CustomerToSale = lazy(() => import('./features/customerToSale/CustomerToSale.jsx'));

//Share Plan
const SharePlanPartner = (lazy(() => import('./features/sharePlanPartner/SharePlanPartner.jsx')));
const SharePlanForNI = lazy(() => import('./features/sharePlanPartner/SharePlanForNI.jsx'));
const SharePlanForChangePlanRenewal = lazy(() => import('./features/sharePlanPartner/SharePlanForChangePlanRenewal.jsx'));

//Claim
const ClaimPartner = lazy(() => import('./features/claimPartner/ClaimPartner.jsx'));

//License Approvals
const LicenseApprovalsPending = lazy(() => import('./features/licenseApprovals/LicenseApprovalsPending.jsx'));
const LicenseApprovalsRejected = lazy(() => import('./features/licenseApprovals/LicenseApprovalsRejected.jsx'));

//Extend Validity
const ExtendValidityPartner = lazy(() => import('./features/Customer/ExtendValidityPartner/ExtendValidityPartner.jsx'));

//Coupons
const CouponsForAll = lazy(() => import('./features/couponsPartner/CouponsForAll.jsx'));
const MultiCouponsForMe = lazy(() => import('./features/couponsPartner/MultiCouponsForMe.jsx'));
const SingleCouponForMe = lazy(() => import('./features/couponsPartner/SingleCouponForMe.jsx'));
const CouponRequestReceived = lazy(() => import('./features/couponsPartner/CouponReqReceived.jsx'));

//Pending Renewal
const RenewalExpiringPartner = lazy(() => import('./features/pendingRenewal/RenewalExpiringPartner.jsx'));
const SubscriptionExpiredPartner = lazy(() => import('./features/pendingRenewal/SubscriptionExpiredPartner.jsx'));

//Pending Renewal
const RenewalExpiringRenewal = lazy(() => import('./features/pendingRenewal/RenewalExpiringRenewal.jsx'));
const SubscriptionExpiredRenewal = lazy(() => import('./features/pendingRenewal/SubscriptionExpiredRenewal.jsx'));

//Ledger Draft Statement
const LedgerDraftStatementPartner = lazy(() => import('./features/ledgerDraftStatementPartner/LedgerDraftStatementPartner.jsx'));
const PaymentModePartner = lazy(() => import('./features/ledgerDraftStatementPartner/PaymentModePartner.jsx'));

//Sales Report
const SalesReport = lazy(() => import('./features/salesReport/SalesReport.jsx'));

//Customer Timeline
const CustomerTimelineV2 = lazy(() => import('./features/customerTimeline/CustomerTimelineV2.jsx'));
const SearchCustomerV2 = lazy(() => import('./features/customerTimeline/SearchCustomerV2.jsx'));


load(
    esNumbers,
    esLocalCurrency,
    esCaGregorian,
    esDateFields
);


loadMessages(enMessages, 'en-US');

const App = () => {
    const [contextState, setContextState] = React.useState({
        localeId: 'en-US',
    });

    const onLanguageChange = React.useCallback(
        (event) => { setContextState({ ...contextState, localeId: event.value.localeId }) },
        [contextState, setContextState]
    );

    return (
        <div className="App">
            <LocalizationProvider language={contextState.localeId}>
                <IntlProvider locale={contextState.localeId}>
                    <AppContext.Provider value={{ ...contextState, onLanguageChange }}>
                        <Provider store={store}>
                            <Router history={history}>
                                <ScrollTop />
                                <DrawerRouterContainer>
                                    <Suspense fallback={<LoadingIndicator />}>
                                        <Switch>

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}user-actions`} component={UserActions} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}reports`} component={Reports} />

                                            {['PARTNER', 'SALES', 'RENEWAL', 'CUSTOMER_CARE'].includes(USER_ROLE) ?
                                                <PrivateRoute exact={true} path={`${SITE_BASE_PATH}`} component={NewInstallDashboard} />
                                                :
                                                <PrivateRoute exact={true} path={`${SITE_BASE_PATH}`} component={PublishedPlansList} />
                                            }

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}dashboard/shared`} component={PlanShareDashboard} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}dashboard/:type`} component={NewInstallDashboard} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}plan/trial/list`} component={TrialPlansList} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}plan/published/list`} component={PublishedPlansList} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}plan/specific/published/list`} component={PublishedSpecificPlansList} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}plan/draft/list`} component={DraftPlanList} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}plan/edit`} component={EditPlan} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}plan/add`} component={AddNewPlan} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}plan/add/:name`} component={AddNewPlan} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}plan/downloads`} component={DownloadPlans} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customer/list`} component={CustomerList} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}finance/info`} component={FinanceInfo} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}finance/info/accepted`} component={FinanceInfoAccepted} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}finance/info/pending`} component={FinanceInfoPending} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}finance/info/rejected`} component={FinanceInfoRejected} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}finance/info/download`} component={FinanceDownload} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}finance/details`} component={FinancePendingDetails} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}finance/details/accepted`} component={FinanceAcceptedDetails} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}finance/details/rejected`} component={FinanceRejectedDetails} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}partner-payments/pending`} component={PartnerPendingPayments} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}partner-payments/accepted`} component={PartnerAcceptedPayments} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}partner-payments/rejected`} component={PartnerRejectedPayments} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}partner-credit-debit-notes`} component={PartnerCreditDebitNotes} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}recall-subscription`} component={RecallSubscriptionList} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}recall-history`} component={RecallHistory} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}margin/profile/download`} component={DownloadMarginProfiles} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}margin/profile/:user`} component={MarginProfile} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}margin/profile/allprofile/associate_partner`} component={AssociatePartner} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}margin/profile/allprofile/:user`} component={EditMarginProfile} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}price/calculator`} component={PriceCalculator} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}price/quotation/history`} component={ViewQuotationHistory} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}profile`} component={SelfProfile} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}admin`} component={Admin} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}profile-details`} component={ProfileDetails} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}finduser`} component={FindUser} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}user/:type`} component={UserDetail} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}assignplan`} component={AssignPlan} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}permission-denied`} component={UnAuthorizedAccess} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}claim/status`} component={StatusCustomerClaim} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}claim/customer`} component={CustomerClaim} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}claim/accepted`} component={AcceptedClaim} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}claim/rejected`} component={RejectedClaim} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}claim/pending`} component={PendingClaim} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}coupones/bulk`} component={BulkDiscountCoupon} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}coupones/received/:status`} component={RcvdCouponRequest} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}coupones/create`} component={CreateNewCoupon} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}coupones/edit`} component={ViewEditBulkCoupon} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}coupones/:tab/:status/new`} component={CreateCustomerCoupon} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}coupones/customer/:status`} component={CustomerCoupon} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}discount/master`} component={MasterDiscount} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}discount/user`} component={UserDiscount} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}discount/approval_hierarchy`} component={DiscountApprovalHierarchy} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}org_chart`} component={OrgChart} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/timeline`} component={CustomerTimeline} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/search`} component={SearchCustomer} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/extend-validity`} component={ExtendValidity} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/revoke-plan`} component={RevokePlan} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/change-org-type`} component={ChangeOrgType} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/lock_customer`} component={LockCustomer} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/view_lock_customer`} component={ViewLockCustomer} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/lock-in-period-expiring`} component={LockInPeriodExpiring} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/lock-in-period-expired`} component={LockInPeriodExpired} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}ledger/draft-statement`} component={LedgerDraftStatement} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}ledger/other_payment_mode`} component={PaymentMode} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}authorize-user`} component={AuthorizeUser} />


                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers-to-sale`} component={CustomerToSale} />
                                            
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}share-plan/partner`} component={SharePlanPartner} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}share-plan-new-installation/partner`} component={SharePlanForNI} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}share-plan-new-installation/sales`} component={SharePlanForNI} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}share-plan-change-plan-and-renewal/partner`} component={SharePlanForChangePlanRenewal} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}share-plan-change-plan-and-renewal/renewal`} component={SharePlanForChangePlanRenewal} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}claims/pending-rejected`} component={ClaimPartner} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}license-approval/pending/partner`} component={LicenseApprovalsPending} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}license-approval/rejected/partner`} component={LicenseApprovalsRejected} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/extend-validity/partner`} component={ExtendValidityPartner} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/extend-validity/sales`} component={ExtendValidityPartner} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customers/extend-validity/renewal`} component={ExtendValidityPartner} />
                                            
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}coupons/for-all`} component={CouponsForAll} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}coupons/multi-use-coupons-for-me`} component={MultiCouponsForMe} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}coupons/single-use-coupons-for-me`} component={SingleCouponForMe} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}coupons/request-received`} component={CouponRequestReceived} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}pending-renewal/partner`} component={RenewalExpiringPartner} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}subscription-expired/partner`} component={SubscriptionExpiredPartner} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}pending-renewal/renewal`} component={RenewalExpiringRenewal} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}subscription-expired/renewal`} component={SubscriptionExpiredRenewal} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}ledger/draft-statement/partner`} component={LedgerDraftStatementPartner} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}ledger/draft-statement/payment-mode/partner`} component={PaymentModePartner} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}sales-report`} component={SalesReport} />

                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customer-timeline`} component={CustomerTimelineV2} />
                                            <PrivateRoute exact={true} path={`${SITE_BASE_PATH}customer-search`} component={SearchCustomerV2} />


                                            <AuthenticationRoute exact={true} path={`${SITE_BASE_PATH}login`} component={LoginScreen} />
                                            <AuthenticationRoute exact={true} path={`${SITE_BASE_PATH}forgetpass`} component={ForgetPass} />
                                            <AuthenticationRoute exact={true} path={`${SITE_BASE_PATH}signup/employee`} component={SignUpEmployee} />
                                            <AuthenticationRoute exact={true} path={`${SITE_BASE_PATH}signup/partner`} component={SignUpPartner} />
                                            <Route component={NotFound} />
                                        </Switch>
                                    </Suspense>
                                </DrawerRouterContainer>
                            </Router>
                        </Provider>
                    </AppContext.Provider>
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}

export default App;
